<template>
  <div>
    <div class="personalIndex">
      <div class="centerIndexHead">
        <div class="t_c">
          <p class="f0404 f30 f_b">{{ userEarningsInfo.drawable }}</p>
          <p class="c_99 f14 mt10">累计收益 (元)</p>
        </div>
        <div class="lineY"></div>
        <div class="centerHeaderImg">
          <img src="@/assets/image/centerIndexImg.png" alt="" />
          <router-link tag="p" :to="{ path: '/upload' }">上传赚钱</router-link>
        </div>
      </div>
      <div class="centerIndexHead">
        <div>
          <p class="f0404 f30 f_b">{{ balance }}</p>
          <p class="c_99 f14 mt10">知币 (个)</p>
        </div>
        <div class="lineY"></div>
        <div class="centerHeaderImg">
          <img src="@/assets/image/centerIndexImg2.png" alt="" />
          <p @click="$topUpPop({ data: { balance: balance } })">充值知币</p>
        </div>
      </div>
    </div>
    <!-- 签到 -->
    <div class="signInBox">
      <div class="signInTitle">
        连续签到<span style="color: #ff9e58; margin-left: 0.1rem"
          >{{ this.signInCount }}天</span
        >
      </div>
      <div class="signInList v">
        <div class="signInItem">
          <div
            :class="{
              heart: day == 1 && this.signInList[1] == 0,
              rotate360: isRotate == 1,
            }"
            @click="signIn(1)"
          >
            <img
              src="@/assets/image/icon/dateActive-icon.png"
              alt=""
              v-if="this.signInList[1] == 1"
            />
            <img src="@/assets/image/icon/date1-icon.png" alt="" v-else />
          </div>
          <p>星期一</p>
        </div>
        <div class="signInItem">
          <div
            :class="{
              heart: day == 2 && this.signInList[2] == 0,
              rotate360: isRotate == 2,
            }"
            @click="signIn(2)"
          >
            <img
              src="@/assets/image/icon/dateActive-icon.png"
              alt=""
              v-if="this.signInList[2] == 1"
            />
            <img src="@/assets/image/icon/date2-icon.png" alt="" v-else />
          </div>
          <p>星期二</p>
        </div>
        <div class="signInItem">
          <div
            :class="{
              heart: day == 3 && this.signInList[3] == 0,
              rotate360: isRotate == 3,
            }"
            @click="signIn(3)"
          >
            <img
              src="@/assets/image/icon/dateActive-icon.png"
              alt=""
              v-if="this.signInList[3] == 1"
            />
            <img src="@/assets/image/icon/date3-icon.png" alt="" v-else />
          </div>
          <p>星期三</p>
        </div>
        <div class="signInItem">
          <div
            :class="{
              heart: day == 4 && this.signInList[4] == 0,
              rotate360: isRotate == 4,
            }"
            @click="signIn(4)"
          >
            <img
              src="@/assets/image/icon/dateActive-icon.png"
              alt=""
              v-if="this.signInList[4] == 1"
            />
            <img src="@/assets/image/icon/date4-icon.png" alt="" v-else />
          </div>
          <p>星期四</p>
        </div>
        <div class="signInItem">
          <div
            :class="{
              heart: day == 5 && this.signInList[5] == 0,
              rotate360: isRotate == 5,
            }"
            @click="signIn(5)"
          >
            <img
              src="@/assets/image/icon/dateActive-icon.png"
              alt=""
              v-if="this.signInList[5] == 1"
            />
            <img src="@/assets/image/icon/date5-icon.png" alt="" v-else />
          </div>
          <p>星期五</p>
        </div>
        <div class="signInItem">
          <div
            :class="{
              heart: day == 6 && this.signInList[6] == 0,
              rotate360: isRotate == 6,
            }"
            @click="signIn(6)"
          >
            <img
              src="@/assets/image/icon/dateActive-icon.png"
              alt=""
              v-if="this.signInList[6] == 1"
            />
            <img src="@/assets/image/icon/date6-icon.png" alt="" v-else />
          </div>
          <p>星期六</p>
        </div>
        <div class="signInItem">
          <div
            :class="{
              heart: day == 7 && this.signInList[7] == 0,
              rotate360: isRotate == 7,
            }"
            @click="signIn(7)"
          >
            <img
              src="@/assets/image/icon/dateActive-icon.png"
              alt=""
              v-if="this.signInList[7] == 1"
            />
            <img src="@/assets/image/icon/date7-icon.png" alt="" v-else />
          </div>
          <p>星期天</p>
        </div>
      </div>
      <div class="signInTip">~连续签到3天可获得1个知币~</div>
    </div>
    <!-- getZhiBi -->
    <div class="getZhiBi">
      <div class="getZhiBiTitle">知币获取</div>
      <div class="bor-b"></div>
      <div class="accessBox">
        <div class="accessItem">
          <div>
            <p class="accessItemName">签约知设网成为课程讲师</p>
            <p class="c_f46 f14">+5知币</p>
          </div>
          <router-link
            v-if="joinStatus != 'checkSuccess'"
            tag="div"
            :to="{ path: '/sign/index' }"
            class="goAccess c_p"
            >去完成</router-link
          >
          <div v-else class="goAccess">已完成</div>
        </div>
        <div class="accessItem">
          <div>
            <p class="accessItemName">邀请1位好友注册成功</p>
            <p class="c_f46 f14">+3知币</p>
          </div>
          <div
            class="goAccess c_p tag-read"
            :data-clipboard-text="url"
            @click="copy"
          >
            去完成
          </div>
        </div>
      </div>
    </div>
    <!-- 月度评比 -->
    <div class="monthly">
      <div class="monthlyTitleBox">
        <div class="getZhiBiTitle">月度评比</div>
        <div class="bor-b"></div>
      </div>
      <div class="accessBox" v-if="isShow && rating && rating[0]">
        <div class="accessItem1">
          <div class="accessItemHead">
            <p class="f16 c_ff">{{ rating[0].monthInfo }}</p>
            <p class="c_ee f14 mt10">评比结果将在每月1号公布</p>
          </div>
          <ul>
            <li
              class="df_Yc mt20"
              v-for="(item, index) in rating[0].topUser"
              :key="index"
            >
              <p class="c_ff9 f_b f18">{{ index + 1 }}</p>
              <div class="userAvatar">
                <img :src="item.avatar" alt="" />
              </div>
              <p class="f0404 f_b f14">{{ item.nc }}（{{ item.mobile }}）</p>
            </li>
          </ul>
        </div>
        <div class="accessItem1">
          <div class="accessItemHead">
            <p class="f16 c_ff">{{ rating[1].monthInfo }}</p>
            <p class="c_ee f14 mt10">评比结果将在每月1号公布</p>
          </div>
          <ul>
            <li
              class="df_Yc mt20"
              v-for="(item, index) in rating[1].topUser"
              :key="index"
            >
              <p class="c_ff9 f_b f18">{{ index + 1 }}</p>
              <div class="userAvatar">
                <img :src="item.avatar" alt="" />
              </div>
              <p class="f0404 f_b f14">{{ item.nc }}（{{ item.mobile }}）</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Clipboard from "clipboard";
import { monthRating, weekSignIn, signInNew, getEarningsInfo } from "@/Api";
import { getToken } from "../../utils/auth";

export default {
  name: "PersonalCenterIndex",
  data() {
    return {
      rating: [],
      isShow: false,
      day: 1,
      signInList: [],
      isRotate: "",
      signInCount: 0,
      userEarningsInfo: {},
      url:''
    };
  },
  components: {},
  created() {
    //邀请链接
    if(this.userInfo.uid)
    this.url = window.location.host + '/#/?inviteUid=' + this.userInfo.uid
    function RetnW() {
      //JS的数组
      var date = new Date();
      var day = date.getDay();
      var weeks = new Array(7, 1, 2, 3, 4, 5, 6);
      var week = weeks[day];
      return week;
    }
    this.day = RetnW();
    //获取用户收益
    getEarningsInfo(getToken()).then((res) => {
      this.userEarningsInfo = res.data;
    });
    //获取签到列表
    weekSignIn(getToken()).then((res) => {
      this.signInList = res.data.week;
      this.signInCount = res.data.count;
    });
    //获取月度评比
    monthRating(getToken()).then((res) => {
      this.rating = res.data;
      this.isShow = true;
    });
  },
  methods: {
    signIn(d) {
      if (d == this.day) {
        signInNew(getToken()).then((res) => {
          if (res.data.msg == "签到成功") {
            this.signInList[d] = 1;
            this.isRotate = d;
            this.signInCount += 1;
            this.$forceUpdate()
          }
        });
      }
    },
    copy() {
      let clipboard = new Clipboard(".tag-read");
      clipboard.on("success", () => {
        this.$message.success("复制成功，快去邀请吧");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message.error("复制失败，请切换浏览器重试");
        clipboard.destroy();
      });
    },
  },
  computed: {
    ...mapGetters(["joinStatus", "balance","userInfo"]),
  },
};
</script>

<style scoped>
.userAvatar {
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 0.2rem;
}
.userAvatar img {
  width: 100%;
}
.accessItemHead {
  background: #8486f8;
  border-radius: 0.08rem;
  height: 00.8rem;
  padding: 0.15rem 0.2rem;
}
.accessBox {
  margin-top: 00.4rem;
  margin-right: -0.5rem;
  display: flex;
  justify-content: space-between;
}
.goAccess {
  width: 0.8rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  color: #f46600;
  border: 0.01rem solid #f46600;
  border-radius: 0.15rem;
}
.accessItemName {
  color: #6c6cea;
  font-size: 0.16rem;
  font-weight: bold;
  margin-bottom: 0.28rem;
}
.accessItem {
  flex: 1;
  margin-right: 0.5rem;
  padding: 0.2rem 0.3rem;
  border-radius: 0.06rem;
  box-shadow: 0px 0.03rem 0.06rem rgba(102, 102, 102, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accessItem1 {
  flex: 1;
  margin-right: 0.5rem;
  padding: 0.2rem 0.3rem;
  border-radius: 0.06rem;
  box-shadow: 0px 0.03rem 0.06rem rgba(102, 102, 102, 0.1);
}
.monthly {
  margin-top: 0.2rem;
  padding: 0.2rem 0.6rem 0.6rem 0.6rem;
  border-radius: 0.04rem;
  background: #ffffff;
}
.bor-b {
  width: 0.8rem;
  height: 0.08rem;
  background: #ff9e58;
  margin: -0.1rem auto 0;
}
.getZhiBiTitle {
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  color: #040404;
}
.getZhiBi {
  padding: 0.2rem 0.6rem 0.4rem;
  border-radius: 0.04rem;
  background: #ffffff;
}
.signInTip {
  width: 4rem;
  line-height: 0.4rem;
  height: 0.4rem;
  text-align: center;
  color: #f46600;
  font-size: 0.16rem;
  border: 0.01rem dashed #f46600;
  border-radius: 0.2rem;
  margin: 0.5rem auto 0;
}
.signInBox {
  border-radius: 0.04rem;
  background: #ffffff;
  margin: 0.2rem 0;
  padding: 0.2rem 0 0.4rem;
}
.signInItem{
  cursor: pointer;
}
.signInItem img {
  width: 0.8rem;
  height: 0.8rem;
}
.signInItem p {
  color: #040404;
  font-size: 0.14rem;
  font-weight: bold;
  text-align: center;
}
.signInList {
  padding: 0 1.05rem;
  display: flex;
  margin-top: 0.4rem;
  align-items: center;
  justify-content: space-between;
}
.signInTitle {
  text-align: center;
  color: #040404;
  font-size: 0.24rem;
  font-weight: bold;
}
.personalIndex {
  display: flex;
  justify-content: space-between;
  margin-right: -0.2rem;
}
.centerHeaderImg {
  display: flex;
  align-items: center;
}
.centerHeaderImg img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
}
.centerHeaderImg p {
  cursor: pointer;
  width: 1rem;
  color: #f46600;
  font-size: 0.14rem;
  padding: 0.08rem 0;
  text-align: center;
  border: 0.01rem solid #f46600;
  border-radius: 0.04rem;
}
.centerIndexHead {
  display: flex;
  align-items: center;
  height: 1.5rem;
  background: #fff;
  border-radius: 0.04rem;
  justify-content: space-around;
  flex: 1;
  margin-right: 0.2rem;
}
.lineY {
  width: 0.02rem;
  height: 00.4rem;
  background: #d9d9d9;
}

.heart {
  animation: heartbeat 1s infinite;
  -webkit-animation: heartbeat 1s infinite;
  /* Safari 和 Chrome */
}

@keyframes heartbeat {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  25% {
    transform: scale(1.2, 1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.rotate360 {
  animation: rotate360 0.5s ease-out 0s;
}

@keyframes rotate360 {
  100% {
    transform: rotate(360deg);
  }
}
</style>